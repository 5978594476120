<div class="support-documents white-content-page">
    <upsc-page-header [pageTitle]="'siteMenu.support.supportDocuments'|translate">
    </upsc-page-header>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</div>

<ng-template #content>
    <div class="form-body">
        <div class="subtitle">
            {{'supportDocuments.internationalShippingDocuments'|translate}}
        </div>
        <div class="documents">
            <div class="document" *ngFor="let file of fileList">
                <div> {{ file.Label }}</div>
                <upsc-s3-file-downloader [bucketName]="'members-pdf.upsc'"
                                         [fileName]="file.fileName"
                                         [buttonId]="generateButtonId(file.fileName)"
                                         [buttonText]="'downloadPdf'|translate"
                                         [buttonClass]="'alternate-button'"></upsc-s3-file-downloader>
            </div>
        </div>
    </div>
</ng-template>