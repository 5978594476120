<div class="supply-order white-content-page">
    <upsc-page-header [pageTitle]="'OrderSupplies'|translate">

        <div page-actions>
            <label class="last-supply-order" *ngIf="showRecentOrder" >
                {{ 'supplyOrder.lastSupplyOrder' | translate }} <label class="date" >{{ recentOrder?.RequestDate | date : 'MM/dd/yyyy' }} </label>. 
                <a href="javascript:;" class="detail" (click)="openOrderDetail(recentOrder?.RequestId)" > {{ 'supplyOrder.viewOrderDetail' | translate }}</a>
                <a href="javascript:;" class="close-button" (click)="hideRecentOrderLink()" ><i class="material-icons">close</i></a>                
            </label>
                <button type="button" 
                mat-raised-button
                class="alternative-button action" 
                (click)="goToHistory()" >
                {{ 'supplyOrder.viewOrderHistory' | translate }}
        </button>
        </div>
    </upsc-page-header>
    
    <div class="last-supply-order-mobile" *ngIf="showRecentOrder" >
            {{ 'supplyOrder.lastSupplyOrder' | translate }} <label class="date" >{{ recentOrder?.RequestDate | date : 'MM/dd/yyyy' }} </label>. 
            <a href="javascript:;" class="close-button" (click)="hideRecentOrderLink()" ><i class="material-icons">close</i></a> 
            <div>
            <a href="javascript:;" class="detail" (click)="openOrderDetail(recentOrder?.RequestId)" > {{ 'supplyOrder.viewOrderDetail' | translate }}</a>  
        </div>              
    </div>

    <mat-horizontal-stepper linear #stepper>
        <mat-step [stepControl]="stepOneFormGroup" [editable]="!isOrderSuccess">
            <ng-template matStepLabel>{{ 'PickYourSupplies' | translate }}</ng-template>
            <ng-container [ngTemplateOutlet]="pickSupplies"
                          [ngTemplateOutletContext]="{formGroup: supplyOrderFormGroup}"></ng-container>
            <div class="mat-step-actions">
                <button mat-raised-button
                        matStepperNext
                        type="button"
                        class="mat-step-action"
                        [disabled]="!stepOneFormGroup.valid">
                    Next: {{'ConfirmMailingAddress'|translate}}
                </button>
            </div>
        </mat-step>
        <mat-step [stepControl]="stepTwoFormGroup" [editable]="!isOrderSuccess">
            <ng-template matStepLabel>{{ 'MailingAddress' | translate }}</ng-template>
            <ng-container [ngTemplateOutlet]="confirmAddress"
                          [ngTemplateOutletContext]="{formGroup: supplyOrderFormGroup}"></ng-container>
            <div class="mat-step-actions">
                <button mat-raised-button
                        type="button"
                        class="mat-step-action"
                        (click)="submitSupplyOrder($event, formGroup.value)">
                    {{'SubmitOrder'|translate}}
                </button>
            </div>
        </mat-step>
        <mat-step [editable]="isOrderSuccess" [completed]="isOrderSuccess">
            <ng-template matStepLabel>{{ 'OrderConfirmation' | translate }}</ng-template>
            <ng-container [ngTemplateOutlet]="orderComplete"></ng-container>
        </mat-step>
    </mat-horizontal-stepper>
</div>

<ng-template #pickSupplies let-formGroup="formGroup">
    <div class="notice">{{'Msg_ContactForMoreVolume'|translate}}</div>
    <form class="ups-form" [formGroup]="formGroup">
        <div class="form-body">
            <div class="form-sections">
                <div class="form-section">
                    <div class="form-section-header">
                        <h5 class="form-section-title">
                            <img src="../../../assets/icons/icon-ups.png" alt="UPS">
                            <span>{{'Supplies'|translate}}</span>
                        </h5>
                    </div>

                    <div class="supply-categories">
                        <div class="deprecated-key-values">
                            <div class="key-value header">
                                <div class="key">{{'Pouches'|translate}}</div>
                                <div class="value">{{'Quantity'|translate}}</div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'Domestic'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="upsPouchDomestic">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of upsPouchDomesticOptions" value="{{ item }}">
                                                {{ item }} {{'Pak'|translate}}(s) ({{ +item * 100 }})
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'International'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="upsPouchInternational">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of upsPouchInternationalOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Pouches'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="deprecated-key-values">
                            <div class="key-value header">
                                <div class="key">{{'ExpressBoxes'|translate}}</div>
                                <div class="value">{{'Quantity'|translate}}</div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'SmallBoxes'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="upsExpressSmallBox">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of upsExpressSmallBoxOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Carton'|translate}}(s) ({{ +item * 20 }})
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'MediumBoxes'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="upsExpressMediumBox">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of upsExpressMediumBoxOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Carton'|translate}}(s) ({{ +item * 20 }})
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'LargeBoxes'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="upsExpressLargeBox">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of upsExpressLargeBoxOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Carton'|translate}}(s) ({{ +item * 20 }})
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="deprecated-key-values">
                            <div class="key-value header">
                                <div class="key">{{'ExpressPackaging'|translate}}</div>
                                <div class="value">{{'Quantity'|translate}}</div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'Envelopes'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="upsExpressEnvelope">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of upsExpressEnvelopOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Carton'|translate}} ({{ +item * 50 }})
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'PaddedPaks'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="upsExpressPaddedPak">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of upsExpressPaddedPakOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Paks'|translate}} ({{ +item * 25 }})
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="deprecated-key-values">
                            <div class="key-value header">
                                <div class="key">{{'Labels'|translate}}</div>
                                <div class="value">{{'Labels'|translate}}</div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'Peel-and-Stick'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="upsLabelPeelAndStick">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of upsLabelPeelAndStickOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Pack'|translate}}(s) ({{ +item * 50 }})
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'ThermalPrinterRolls'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="upsLabelThermalPrinterRoll">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of upsLabelThermalPrinterRollOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Roll'|translate}}(s) ({{ +item * 320 }})
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-section">
                    <div class="form-section-header">
                        <h5 class="form-section-title">
                            <img src="../../../assets/icons/icon-fedex.png" alt="Fedex">
                            <span>{{'Supplies'|translate}}</span>
                        </h5>
                    </div>

                    <div class="supply-categories">
                        <div class="deprecated-key-values">
                            <div class="key-value header">
                                <div class="key">{{'Pouches'|translate}}</div>
                                <div class="value">{{'Quantity'|translate}}</div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'DomesticAirbill'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="fedexPouchDomestic">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of fedexPouchDomesticOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Pouches'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'InternationalAirbill'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="fedexPouchInternational">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of fedexPouchInternationalOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Pouches'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="deprecated-key-values">
                            <div class="key-value header">
                                <div class="key">{{'Boxes'|translate}}</div>
                                <div class="value">{{'Quantity'|translate}}</div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'SmallBoxes'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="fedexBoxSmall">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of fedexBoxSmallOptions" value="{{ item }}">
                                                {{ item }} {{'Boxes'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'MediumBoxes'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="fedexBoxMedium">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of fedexBoxMediumOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Boxes'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'LargeBoxes'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="fedexBoxLarge">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of fedexBoxLargeOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Boxes'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="deprecated-key-values">
                            <div class="key-value header">
                                <div class="key">{{'Paks'|translate}}</div>
                                <div class="value">{{'Quantity'|translate}}</div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'SmallPaks'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="fedexPakSmall">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of fedexPakSmallOptions" value="{{ item }}">
                                                {{ item }} {{'Paks'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'LargePaks'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="fedexPakLarge">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of fedexPakLargeOptions" value="{{ item }}">
                                                {{ item }} {{'Paks'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="deprecated-key-values">
                            <div class="key-value header">
                                <div class="key">{{'Labels'|translate}}</div>
                                <div class="value">{{'Quantity'|translate}}</div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'InternationalAirWaybill'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="fedexLabelInternational">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of fedexLabelInternationalOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Waybills'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'LaserLabel'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="fedexLabelLaser">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of fedexLabelLaserOptions" value="{{ item }}">
                                                {{ item }} {{'Label'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="deprecated-key-values">
                            <div class="key-value header">
                                <div class="key">{{'Envelopes'|translate}}</div>
                                <div class="value">{{'Quantity'|translate}}</div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'RegularWithPouch'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="fedexEnvelopeRegular">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of fedexEnvelopeRegularOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Envelopes'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="key-value">
                                <div class="key">{{'LegalSize'|translate}}</div>
                                <div class="value">
                                    <mat-form-field>
                                        <mat-select formControlName="fedexEnvelopeLegal">
                                            <mat-option selected value="0">{{'Select'|translate}}</mat-option>
                                            <mat-option *ngFor="let item of fedexEnvelopeLegalOptions"
                                                        value="{{ item }}">
                                                {{ item }} {{'Envelopes'|translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-footer">
            <div class="form-actions">
            </div>
        </div>
    </form>
</ng-template>
<ng-template #confirmAddress let-formGroup="formGroup">
    <form class="ups-form" [formGroup]="formGroup">
        <div class="form-body">
            <div class="form-sections">
                <div class="form-section">
                    <div class="form-section-header">
                        <div class="form-section-title">{{'MailingAddress'|translate}}</div>
                    </div>

                    <div class="ship-to">
                        <h5>{{'ShipTo'|translate}}</h5>
                        <p> {{supplyOrder.FirstName}} {{supplyOrder.LastName}} </p>
                        <p> {{supplyOrder.StreetAddress}} {{supplyOrder.ApartmentSuite}} </p>
                        <p> {{supplyOrder.City}} {{supplyOrder.State}} {{supplyOrder.Zip}} {{supplyOrder.Country}} </p>
                        <p> {{TelephoneNo}} </p>

                        <div class="address-actions">
                            <button mat-raised-button
                                    type="button"
                                    class="address-action"
                                    (click)="openEditEmail($event)">{{'Edit'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-section">
                    <div class="form-section-header">
                        <div class="form-section-title">{{'SpecialShippingInstructions'|translate}}</div>
                    </div>

                    <p>{{'Msg_SpecificVolumeRequest'|translate}}</p>
                    <mat-form-field>
                    <textarea matInput
                              maxlength="1000"
                              placeholder="{{'specialInstructions'|translate}}"
                              formControlName="specialInstructions"></textarea>
                    </mat-form-field>
                </div>
                <!-- <div class="form-section">
                    <div class="form-section-header">
                        <div class="form-section-title">{{'OrderConfirmation'|translate}}</div>
                    </div>

                    <p>{{'OrderConfirmationMsg1'|translate}}</p>
                    <p>{{'OrderConfirmationMsg2'|translate}}</p>
                    <p>{{'OrderConfirmationMsg3'|translate}}</p>
                </div> -->
            </div>
        </div>
    </form>
</ng-template>
<ng-template #orderComplete>
    <h4> {{'OrderConfirmation'|translate}} </h4>
    <p>{{'OrderConfirmationMsg1'|translate}}</p>
    <p>{{'OrderConfirmationMsg2'|translate}}</p>
    <p>{{'OrderConfirmationMsg3'|translate}}</p>

    <div class="mat-step-actions">
        <button mat-raised-button
                type="button"
                class="mat-step-action"
                (click)="restartSteps($event)">
            {{'orderMoreSupplies'|translate}}
        </button>
    </div>
</ng-template>

<!--<form #supplyOrderForm="ngForm" novalidate>-->
<!--<div class="row ml-12 ">-->
<!--<div class="col-md-10 col-sm-10 col-xs-12 font-E6">-->
<!--<h4>-->
<!--<span [class.orange-font]="pickSupply">1. {{'PickYourSupplies'|translate}} </span>-->
<!--<span class="pl-8" [class.orange-font]="confirmMailingAddress"> 2. {{'ConfirmYourMailingAddress'|translate}} </span>-->
<!--<span class="pl-8" [class.orange-font]="orderComplete"> 3. {{'OrderComplete'|translate}} </span>-->
<!--</h4>-->
<!--</div>-->
<!--<div class="col-md-2 col-sm-2 col-xs-12 text-smaller black-font">-->
<!--<div class="row">-->
<!--<div class="col-md-9 col-sm-9 col-xs-8 ">-->
<!--<strong> {{'TotalItems'|translate}}:</strong>-->
<!--</div>-->
<!--<div class="col-md-3 col-sm-3 col-xs-4 ">-->
<!--<strong> {{totalOrderItems}} </strong>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div *ngIf="pickSupply" class="row row-bottom-2 black-font ml-12">-->
<!--<div class="col-md-12 col-sm-12 col-xs-12 ml-12">-->
<!--{{'Msg_ContactForMoreVolume'|translate}}-->
<!--</div>-->
<!--</div>-->
<!--<div *ngIf="pickSupply" class="row row-bottom-2 bg-verylightgrey ml-12">-->
<!--<div class="row row-bottom-2">-->
<!--<div class="col-md-12 col-sm-12 col-xs-12 ml-12 orange-font">-->
<!--<img src="../../../assets/images/logo_ups.png" alt="UPS"> <label class="ctrl-label"-->
<!--style="vertical-align: sub;"><h4>-->
<!--{{'Supplies'|translate}} </h4></label>-->
<!--</div>-->
<!--</div>-->
<!--&lt;!&ndash;First Row&ndash;&gt;-->
<!--<div class="row row-bottom-2">-->
<!--&lt;!&ndash;First Column&ndash;&gt;-->
<!--<div class="col-md-3 col-sm-3 col-xs-12 ">-->
<!--<div class="row form-group black-borderb-1 ml-12 font-5b">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'Pouches'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--{{'Quantity'|translate}}-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'Domestic'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="upsDomestic"-->
<!--name="upsDomestic"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.Pouch"-->
<!--&gt;-->
<!--<mat-option value="0">Select</mat-option>-->
<!--<mat-option value="1">1 {{'Pak'|translate}} (100)</mat-option>-->
<!--<mat-option value="1">2 {{'Pak'|translate}}(s) (200)</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'International'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="upsInternational"-->
<!--name="upsInternational"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.InternationalPouch"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">Select</mat-option>-->
<!--<mat-option value="25">25 {{'Pouches'|translate}}</mat-option>-->
<!--<mat-option value="50">50 {{'Pouches'|translate}}</mat-option>-->
<!--<mat-option value="75">75 {{'Pouches'|translate}}</mat-option>-->
<!--<mat-option value="100">100 {{'Pouches'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-md-1 col-sm-1 ">-->
<!--</div>-->
<!--&lt;!&ndash;Second Column&ndash;&gt;-->
<!--<div class="col-md-3 col-sm-3 col-xs-12 ">-->
<!--<div class="row form-group black-borderb-1 ml-12 font-5b">-->
<!--<div class="col-md-8 col-sm-8 col-xs-6 text-left clear-col-pad">-->
<!--{{'ExpressPackaging'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-4 col-sm-4 col-xs-6 text-right clear-col-pad">-->
<!--{{'Quantity'|translate}}-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'Envelopes'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="ExpressEnvelopes"-->
<!--name="ExpressEnvelopes"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.ExpressEnvelopes"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">Select</mat-option>-->
<!--<mat-option value="1">1 {{'Carton'|translate}} (50)</mat-option>-->
<!--<mat-option value="2">2 {{'Carton'|translate}} (100)</mat-option>-->
<!--<mat-option value="3">3 {{'Carton'|translate}} (150)</mat-option>-->
<!--<mat-option value="4">4 {{'Carton'|translate}} (200)</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'PaddedPaks'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="ExpressPaddedPak"-->
<!--name="ExpressPaddedPak"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.ExpressPaddedPak"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">Select</mat-option>-->
<!--<mat-option value="1">1 {{'Pak'|translate}} (25)</mat-option>-->
<!--<mat-option value="2">2 {{'Paks'|translate}} (50)</mat-option>-->
<!--<mat-option value="3">3 {{'Paks'|translate}} (100)</mat-option>-->
<!--<mat-option value="4">4 {{'Paks'|translate}} (200)</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-md-1 col-sm-1 ">-->
<!--</div>-->
<!--&lt;!&ndash;Third Column&ndash;&gt;-->
<!--<div class="col-md-3 col-sm-3 col-xs-12 ">-->
<!--<div class="row form-group black-borderb-1 ml-12 font-5b">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'Labels'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--{{'Quantity'|translate}}-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'Peel-and-Stick'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="ExpressPeelStickLabel"-->
<!--name="ExpressPeelStickLabel"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.ExpressPeelStickLabel"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">Select</mat-option>-->
<!--<mat-option value="1">1 {{'Pack'|translate}} (50)</mat-option>-->
<!--<mat-option value="2">2 {{'Packs'|translate}} (100)</mat-option>-->
<!--<mat-option value="3">3 {{'Packs'|translate}} (150)</mat-option>-->
<!--<mat-option value="4">4 {{'Packs'|translate}} (200)</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'ThermalPrinterRolls'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="ThermalPrinterRoll"-->
<!--name="ThermalPrinterRoll"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.ThermalPrinterRoll"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">Select</mat-option>-->
<!--<mat-option value="1">1 {{'Roll'|translate}} (320)</mat-option>-->
<!--<mat-option value="2">2 {{'Rolls'|translate}} (640)</mat-option>-->
<!--<mat-option value="3">3 {{'Rolls'|translate}} (960)</mat-option>-->
<!--<mat-option value="4">4 {{'Rolls'|translate}} (1280)</mat-option>-->
<!--<mat-option value="5">5 {{'Rolls'|translate}} (1600)</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-md-1 col-sm-1 ">-->
<!--</div>-->
<!--</div>-->
<!--&lt;!&ndash;Second Row&ndash;&gt;-->
<!--<div class="row row-bottom-2">-->
<!--&lt;!&ndash;First Column&ndash;&gt;-->
<!--<div class="col-md-3 col-sm-3 col-xs-12 ">-->
<!--<div class="row form-group black-borderb-1 ml-12 font-5b">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'ExpressBoxes'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--{{'Quantity'|translate}}-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'SmallBoxes'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="ExpressSmallBox"-->
<!--name="ExpressSmallBox"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.ExpressSmallBox"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">{{'Select'|translate}}</mat-option>-->
<!--<mat-option value="1">1 {{'Carton'|translate}} (20)</mat-option>-->
<!--<mat-option value="2">2 {{'Carton'|translate}} (40)</mat-option>-->
<!--<mat-option value="3">3 {{'Carton'|translate}} (60)</mat-option>-->
<!--<mat-option value="4">4 {{'Carton'|translate}} (80)</mat-option>-->
<!--<mat-option value="5">5 {{'Carton'|translate}} (100)</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'MediumBoxes'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="ExpressMeduimBox"-->
<!--name="ExpressMeduimBox"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.ExpressMeduimBox"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">Select</mat-option>-->
<!--<mat-option value="1">1 {{'Carton'|translate}} (20)</mat-option>-->
<!--<mat-option value="2">2 {{'Carton'|translate}} (40)</mat-option>-->
<!--<mat-option value="3">3 {{'Carton'|translate}} (60)</mat-option>-->
<!--<mat-option value="4">4 {{'Carton'|translate}} (80)</mat-option>-->
<!--<mat-option value="5">5 {{'Carton'|translate}} (100)</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'LargeBoxes'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="ExpressLargeBox"-->
<!--name="ExpressLargeBox"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.ExpressLargeBox"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">{{'Select'|translate}}</mat-option>-->
<!--<mat-option value="1">1 {{'Carton'|translate}} (20)</mat-option>-->
<!--<mat-option value="2">2 {{'Carton'|translate}} (40)</mat-option>-->
<!--<mat-option value="3">3 {{'Carton'|translate}} (60)</mat-option>-->
<!--<mat-option value="4">4 {{'Carton'|translate}} (80)</mat-option>-->
<!--<mat-option value="5">5 {{'Carton'|translate}} (100)</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-md-1 col-sm-1 ">-->
<!--</div>-->
<!--&lt;!&ndash;Second Column&ndash;&gt;-->
<!--<div class="col-md-4 col-sm-4 col-xs-12 ">-->
<!--</div>-->
<!--&lt;!&ndash;Third Column&ndash;&gt;-->
<!--<div class="col-md-4 col-sm-4 col-xs-12 ">-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div *ngIf="pickSupply" class="row row-bottom-2 bg-verylightgrey ml-12">-->
<!--<div class="row row-bottom-2">-->
<!--<div class="col-md-12 col-sm-12 ml-12 col-xs-12 orange-font">-->
<!--<img src="../../../assets/images/FedEx-Logo.png" alt="UPS"> <label class="ctrl-label"-->
<!--style="vertical-align: sub;"><h4>-->
<!--{{'Supplies'|translate}} </h4></label>-->
<!--</div>-->
<!--</div>-->
<!--&lt;!&ndash;Third Row&ndash;&gt;-->
<!--<div class="row row-bottom-2">-->
<!--&lt;!&ndash;First Column&ndash;&gt;-->
<!--<div class="col-md-3 col-sm-3 col-xs-12 ">-->
<!--<div class="row form-group black-borderb-1 ml-12 font-5b">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'Pouches'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--{{'Quantity'|translate}}-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'DomesticAirbill'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="FedExDomesticAirbillPouch"-->
<!--name="FedExDomesticAirbillPouch"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.FedExDomesticAirbillPouch"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">Select</mat-option>-->
<!--<mat-option value="50">50 {{'Pouches'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'InternationalAirbill'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="FedexInternationalAirbillPouch"-->
<!--name="FedexInternationalAirbillPouch"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.FedexInternationalAirbillPouch"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">{{'Select'|translate}}</mat-option>-->
<!--<mat-option value="50">50 {{'Pouches'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-md-1 col-sm-1 ">-->
<!--</div>-->
<!--&lt;!&ndash;Second Column&ndash;&gt;-->
<!--<div class="col-md-3 col-sm-3 col-xs-12 ">-->
<!--<div class="row form-group black-borderb-1 ml-12 font-5b">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'Boxes'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--{{'Quantity'|translate}}-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'SmallBoxes'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="FedexSmallBox"-->
<!--name="FedexSmallBox"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.FedexSmallBox"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">{{'Select'|translate}}</mat-option>-->
<!--<mat-option value="10">10 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="20">20 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="30">30 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="40">40 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="50">50 {{'Boxes'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'MediumBoxes'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="FedexMediumBox"-->
<!--name="FedexMediumBox"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.FedexMediumBox"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">{{'Select'|translate}}</mat-option>-->
<!--<mat-option value="10">10 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="20">20 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="30">30 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="40">40 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="50">50 {{'Boxes'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'LargeBoxes'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="FedexLargeBox"-->
<!--name="FedexLargeBox"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.FedexLargeBox"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">{{'Select'|translate}}</mat-option>-->
<!--<mat-option value="10">10 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="20">20 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="30">30 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="40">40 {{'Boxes'|translate}}</mat-option>-->
<!--<mat-option value="50">50 {{'Boxes'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-md-1 col-sm-1 ">-->
<!--</div>-->
<!--&lt;!&ndash;Third Column&ndash;&gt;-->
<!--<div class="col-md-3 col-sm-3 col-xs-12 ">-->
<!--<div class="row form-group black-borderb-1 ml-12 font-5b">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'Paks'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--{{'Quantity'|translate}}-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'SmallPaks'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="FedexSmallPak"-->
<!--name="FedexSmallPak"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.FedexSmallPak"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">{{'Select'|translate}}</mat-option>-->
<!--<mat-option value="25">25 {{'Paks'|translate}}</mat-option>-->
<!--<mat-option value="50">50 {{'Paks'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'LargePaks'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="FedexLargePak"-->
<!--name="FedexLargePak"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.FedexLargePak"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">Select</mat-option>-->
<!--<mat-option value="25">25 {{'Paks'|translate}}</mat-option>-->
<!--<mat-option value="50">50 {{'Paks'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-md-1 col-sm-1 ">-->
<!--</div>-->
<!--</div>-->
<!--&lt;!&ndash;Fourth Row&ndash;&gt;-->
<!--<div class="row row-bottom-2">-->
<!--&lt;!&ndash;First Column&ndash;&gt;-->
<!--<div class="col-md-3 col-sm-3 col-xs-12 ">-->
<!--<div class="row form-group black-borderb-1 ml-12 font-5b">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'Labels'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--{{'Quantity'|translate}}-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'InternationalAirWaybill'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="FedExInternationalAirWayBill"-->
<!--name="FedExInternationalAirWayBill"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.FedExInternationalAirWayBill"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">{{'Select'|translate}}</mat-option>-->
<!--<mat-option value="50">50 {{'Waybills'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'LaserLabel'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="FedexLaserLabel"-->
<!--name="FedexLaserLabel"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.FedexLaserLabel"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">Select</mat-option>-->
<!--<mat-option value="200">200 {{'Label'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-md-1 col-sm-1 ">-->
<!--</div>-->
<!--&lt;!&ndash;Second Column&ndash;&gt;-->
<!--<div class="col-md-3 col-sm-3 col-xs-12 ">-->
<!--<div class="row form-group black-borderb-1 ml-12 font-5b">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'Envelopes'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--{{'Quantity'|translate}}-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'RegularWithPouch'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="FedexEnvelopePouch"-->
<!--name="FedexEnvelopePouch"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.FedexEnvelopePouch"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">{{'Select'|translate}}</mat-option>-->
<!--<mat-option value="25">25 {{'Envelopes'|translate}}</mat-option>-->
<!--<mat-option value="50">50 {{'Envelopes'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row form-group ml-12 font-43">-->
<!--<div class="col-md-6 col-sm-6 col-xs-6 text-left clear-col-pad">-->
<!--{{'LegalSize'|translate}}-->
<!--</div>-->
<!--<div class=" col-md-6 col-sm-6 col-xs-6 text-right clear-col-pad">-->
<!--<mat-form-field>-->
<!--<mat-select id="FedexLegalSizeEnvelope"-->
<!--name="FedexLegalSizeEnvelope"-->
<!--(change)="supplyOrderItemChange()"-->
<!--[(ngModel)]="supplyOrder.FedexLegalSizeEnvelope"-->
<!--class="border-bottom-1 bg-verylightgrey width85">-->
<!--<mat-option value="0">{{'Select'|translate}}</mat-option>-->
<!--<mat-option value="50">50 {{'Envelopes'|translate}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-md-1 col-sm-1 ">-->
<!--</div>-->
<!--&lt;!&ndash;Third Column&ndash;&gt;-->
<!--<div class="col-md-4 col-sm-4 col-xs-12 ">-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</form>-->
<!--<div *ngIf="confirmMailingAddress" class="row row-bottom-2 ml-12">-->
<!--<div class="row row-bottom-2 mt-8">-->
<!--<div class="col-md-12 col-sm-12 col-xs-12 orange-font">-->
<!--<label class="ctrl-label"><h4> {{'MailingAddress'|translate}} </h4></label>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row row-bottom-2">-->
<!--<div class="col-md-6 col-sm-6 col-xs-11 ml-12">-->
<!--<div class="row row-bottom-2 pl-8">-->
<!--<div class="col-md-8 col-sm-8 col-xs-8 text-uppercase ">-->
<!--<p><strong> {{'ShipTo'|translate}} </strong></p>-->
<!--<p> {{supplyOrder.FirstName}} {{supplyOrder.LastName}} </p>-->
<!--<p> {{supplyOrder.StreetAddress}} {{supplyOrder.ApartmentSuite}} </p>-->
<!--<p> {{supplyOrder.City}} {{supplyOrder.State}} {{supplyOrder.Zip}} {{supplyOrder.Country}} </p>-->
<!--<p> {{supplyOrder.TelephoneNo}} </p>-->
<!--</div>-->
<!--<div class="col-md-3 col-sm-3 col-xs-3 text-right">-->
<!--<a href="#editMailingAddress"-->
<!--(click)="openEditEmail($event)"-->
<!--class="btn btn-sm btn-green text-uppercase white-font"-->
<!--role="button"> {{'Edit'|translate}} </a>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row mt-8">-->
<!--<div class="col-md-12 col-sm-12 col-xs-12 orange-font">-->
<!--<label class="ctrl-label"><h4> {{'SpecialShippingInstructions'|translate}} </h4></label>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row row-bottom-2 ml-12">-->
<!--<div class="col-md-8 col-sm-8 col-xs-12 pl-12">-->
<!--{{'Msg_SpecificVolumeRequest'|translate}}-->
<!--</div>-->
<!--</div>-->
<!--<div class="row row-bottom-2 ml-12">-->
<!--<div class="col-md-8 col-sm-8 col-xs-12 pl-12">-->
<!--<textarea id="specialinstruction"-->
<!--name="specialinstruction"-->
<!--maxlength="1000"-->
<!--class="bg-verylightgrey"-->
<!--[(ngModel)]="supplyOrder.SpecialInstructions"-->
<!--style="width:100%;height:190px;resize:none;"> </textarea>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div *ngIf="orderComplete" class="row row-bottom-2 ml-12">-->
<!--<div class="row row-bottom-2 mt-8">-->
<!--<div class="col-md-12 col-sm-12 col-xs-12 orange-font">-->
<!--<h4> {{'OrderConfirmation'|translate}} </h4>-->
<!--</div>-->
<!--</div>-->
<!--<div class="row row-bottom-2 mt-8">-->
<!--<div class="col-md-12 col-sm-12 col-xs-12 text-normalsize">-->
<!--<p>{{'OrderConfirmationMsg1'|translate}}</p>-->
<!--<p>{{'OrderConfirmationMsg2'|translate}}</p>-->
<!--<p>{{'OrderConfirmationMsg3'|translate}}</p>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!-- Edit Mailing Address -->
<!-- <div class="modal " id="editMailingAddress" tabindex="-1" role="dialog" aria-labelledby="Edit Mailing Address" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form #mailAddressForm [formGroup]="formGroup" (ngSubmit)="updateMailAddress($event, formGroup.value)">
        <div class="modal-header popup-header">
          <div class="row">
            <div class="col-md-8">
              <h4 class="modal-title text-uppercase">{{'EditMailingAddress'|translate}}</h4>
            </div>
            <div class="col-md-4 text-right">
              <button type="submit" class="btn btn-sm btn-green white-font" >{{'Update'|translate}}</button>
              <button type="button" #close data-dismiss="modal" class="btn btn-sm btn-grey black-font">{{'Close'|translate}}</button>
            </div>
          </div>
        </div>
        <div class="modal-body text-capitalize ">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 ">
                <mat-form-field>
              <input id="firstname" name="First Name" 
              formControlName="Firstname" 
              matInput
              required
              onfocus="this.select();"
              placeholder="{{'FirstName'|translate}}" 
              type="text" 
              autocomplete="off">
              </mat-form-field>
            </div>
            <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
            <mat-form-field>
              <input id="lastname" name="Last Name" 
              placeholder="{{'LastName'|translate}}" 
              formControlName="Lastname" 
              required 
              matInput
              onfocus="this.select();"
              type="text" 
              autocomplete="off">
            </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
                <mat-form-field>
              <input id="companyName" name="Company Name" 
              placeholder="Company Name" 
              formControlName="CompanyName" 
              required 
              matInput
              onfocus="this.select();"
              type="text" 
              autocomplete="off">
           
            </mat-form-field>
            </div>
            <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
              <mat-form-field>
              <input id="streetAddress" name="Street Address" 
               placeholder="Street Address" 
               formControlName="StreetAddress" 
               required 
               matInput
               onfocus="this.select();"
               type="text" 
              autocomplete="off">
            </mat-form-field>
              
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
              <input id="suiteApt" name="Suite / Apt"
               placeholder="{{'SuiteOrApartment'|translate}}" 
               formControlName="ApartmentSuite"
               required 
               matInput
               onfocus="this.select();"
               type="text" 
               autocomplete="off">
              
            </div>
            <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
              <mat-form-field>
              <input id="zipCode" name="zipCode" 
              placeholder="{{'Zipcode'|translate}} *" 
              formControlName="zipCode"
              required 
              matInput
              onfocus="this.select();"
              type="text" 
              autocomplete="off" 
              (input)="getAddressbyZipCode($event)">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            
            <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
              <mat-form-field>
              <input id="city" name="City" 
              required placeholder="{{'City'|translate}} *" 
              formControlName="City"
              required 
              matInput
              onfocus="this.select();"
              type="text" 
              autocomplete="off">
              </mat-form-field>
            </div>
            <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
              <mat-form-field>
              <input id="state" name="State" 
              placeholder="{{'State'|translate}} *" 
              formControlName="State"
              required 
              matInput
              onfocus="this.select();"
              type="text" 
              autocomplete="off">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
              <mat-form-field>
              <mat-select id="country" name="Country" formControlName="Country" required>
                    <mat-option *ngFor="let country of countriesList" value="{{country.CountryCode}}" > {{country.CountryName}} </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
              <mat-form-field>
              <input id="phone" name="Phone" 
              placeholder="{{'Phone'|translate}}" 
              formControlName="Phone"
              required 
              matInput
              onfocus="this.select();"
              type="text" 
              autocomplete="off">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
              <mat-form-field>
              <input id="fax" name="Fax" 
              placeholder="{{'Fax'|translate}} " 
              required 
              matInput
              onfocus="this.select();"
              type="text" 
              formControlName="Fax"
                autocomplete="off">
              </mat-form-field>
            </div>
            <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
              <mat-form-field>
              <input id="emailAddress" name="Valid Email Address" 
              placeholder="{{'EmailAddress'|translate}} *" 
              formControlName="EmailAddress"  
              required 
              matInput
              onfocus="this.select();"
              type="text" 
              autocomplete="off">
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div> -->
<!-- /Edit Mailing Address -->
<!-- Supply Order Validation Error -->
<!--<a href="#invalidOrderSupply"
   #invalidOrderSupply
   data-toggle="modal"
   data-dismiss="modal"
   role="button"
   class="hidden"></a>
<div class="modal "
     id="invalidOrderSupply"
     tabindex="-1"
     role="dialog"
     aria-labelledby="Supply Order Validation Error"
     aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body ">
                {{'SelectOrderSuppliesMsg'|translate}}
            </div>
            <div class="modal-footer" style="text-align:center !important;">
                <button type="button" data-dismiss="modal" class="btn btn-sm btn-primary text-uppercase">
                    {{'OK'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>-->
<!-- /Supply Order Validation Error -->
